import React from "react";
import { BookmarkFrontmatter, ChildMarkdownRemark, Edge } from "../models";

const BookmarkGrid: React.FC<BookmarkGridProps> = ({ bookmarks }) => {
  return (
    <div className="grid grid-cols-bookmarks grid-rows-1 gap-4">
      {bookmarks.map((x) => (
        <a
          key={x.node.id}
          className="relative h-0 pt-full"
          href={x.node.frontmatter.url}
        >
          <article className="absolute top-0 right-0 bottom-0 left-0 p-4 flex flex-col justify-end rounded-lg bg-gray-200 transition-colors duration-150 ease-in-out hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900">
            <h2 className="text-xl dark:text-gray-50">
              {x.node.frontmatter.name}
            </h2>
            <h3 className="mt-2 mb-0 text-gray-600 dark:text-gray-300">
              {x.node.frontmatter.description}
            </h3>
          </article>
        </a>
      ))}
    </div>
  );
};

export default BookmarkGrid;

type BookmarkGridProps = {
  bookmarks: Edge<ChildMarkdownRemark<BookmarkFrontmatter>>[];
};

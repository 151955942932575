import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {
  BookmarkFrontmatter,
  ChildMarkdownRemark,
  MarkdownFileNode,
  QueryFragment,
  Connection,
} from "../models";
import BookmarkGrid from "../components/BookmarkGrid";
import MarkdownContent from "../components/MarkdownContent";
import PageHeader from "../components/PageHeader";

type BookmarksPageQueryResult = QueryFragment<"file", MarkdownFileNode> &
  QueryFragment<
    "allMarkdownRemark",
    Connection<ChildMarkdownRemark<BookmarkFrontmatter>>
  >;

const BookmarksPage: React.FC<PageProps> = () => {
  const {
    allMarkdownRemark: { edges: bookmarks },
    file: {
      childMarkdownRemark: { html: prologueHtml },
    },
  } = useStaticQuery<BookmarksPageQueryResult>(graphql`
    query BookmarksPageQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { ne: true } }
          fileAbsolutePath: { regex: "/bookmarks/.*\\\\.md$/" }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              description
              url
            }
          }
        }
      }
      file(sourceInstanceName: { eq: "pages" }, name: { eq: "bookmarks" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Bookmarks" />
      <PageHeader title="Bookmarks" />
      <MarkdownContent html={prologueHtml} />
      <BookmarkGrid bookmarks={bookmarks} />
    </Layout>
  );
};

export default BookmarksPage;

import React from "react";

const MarkdownContent: React.FC<MarkdownContentProps> = ({ html, lang }) => (
  <div
    className="text-content text-gray-900 dark:text-gray-50"
    lang={lang}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

export default MarkdownContent;

export type MarkdownContentProps = { html: string; lang?: string };

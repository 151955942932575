import React from "react";

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => (
  <header className="text-3xl font-light mt-4 mb-6 text-gray-900 dark:text-gray-300">
    {title}
  </header>
);

PageHeader.displayName = "PageHeader";

export default PageHeader;

export type PageHeaderProps = { title: string };
